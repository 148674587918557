import React from 'react';
import { Layout } from '../components/layout';
import { HeadMeta } from '../components/layout/meta';
import { graphql } from 'gatsby';
import { getPublishedIssues } from '../model/issue';
import { Documentary as DocumentaryIssue } from '../components/issues/section-type';

export default function DocumentariesPage({
  data: {
    wpPage: { seo },
    wp: {
      siteOptionsMetaboxSettings: { optionsIssuesHomepage: documentaryIds },
    },
    allWpTghpTaxonomyIssue: { nodes: allDocumentaries },
  },
}) {
  const publishedDocumentaries = getPublishedIssues(documentaryIds, allDocumentaries);

  return (
    <Layout location={'documentaries-listing'} seoData={seo}>
      <div className="issue-grid">
        {publishedDocumentaries.map((issue) => (
          <DocumentaryIssue issue={issue} key={issue.databaseId} />
        ))}
      </div>
    </Layout>
  );
}

export const query = graphql`
  query ($slug: String!) {
    wpPage(slug: { eq: $slug }) {
      ...SeoData
    }

    wp {
      ...SiteOptions
    }

    allWpTghpTaxonomyIssue(filter: { tghpwipIssuesTaxonomyCategory: { eq: "film" } }) {
      nodes {
        ...AllIssues
      }
    }
  }
`;

export const Head = () => <HeadMeta />;
